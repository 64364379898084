import { Controller } from "@hotwired/stimulus"

//
//
// Connects to data-controller="dependant"

export default class extends Controller {
  static values = {
    parentfields: String,
    url: String
  }

  connect() {
    let fieldsSelector = 'select' // add other input types if needed
    this.token = document.querySelector('meta[name="csrf-token"]').content;
    this.dependantField = this.element.querySelector(fieldsSelector)
    this.form = this.element.closest('form')
    this.parentfieldsValue.split(',').forEach(parentName => {
      let parentElement = this.form.querySelector(`[name$="[${parentName}]"]`)
      let childFieldNames = parentElement.dataset.childFieldNames ? parentElement.dataset.childFieldNames.split(',') : []
      if (!childFieldNames.includes(this.dependantField.name)) {
        childFieldNames.push(this.dependantField.name)
        parentElement.addEventListener('change', this.updateField.bind(this))
      }
      parentElement.dataset.childFieldNames = childFieldNames.join(',')
    });
  }

  updateField() {
    let params = new URLSearchParams();
    this.parentfieldsValue.split(',').forEach(elementName => {
      let targetElement = this.form.querySelector(`[name$="[${elementName}]"]`)
      params.set(elementName, targetElement.value)
    });

    let extractedFieldName = this.dependantField.name.split(/[\[\]]/)
    if (extractedFieldName.length == 3) {
      params.set(extractedFieldName[1], this.dependantField.value)
    }else{
      params.set(this.dependantField.name, this.dependantField.value)
    }

    fetch(this.urlValue, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.token
      },
      credentials: 'same-origin',
      body: params
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}
